function fillRange(min: number, max: number) {
  const array: number[] = [];
  for (let i = min; i <= max; i++) {
    array.push(i);
  }
  return array;
}

function addEllipses(
  pageNumbers: (number | '...')[],
  currentPage: number,
  maxPages: number,
) {
  const firstPageNumber = pageNumbers[0];
  if (typeof firstPageNumber === 'number' && firstPageNumber >= 3) {
    pageNumbers.unshift(1, '...');
  }

  if (maxPages > 1 && pageNumbers[pageNumbers.length - 1] !== maxPages) {
    pageNumbers.push('...');
  }

  return pageNumbers;
}

/**
 * Generates an array of page numbers to display in a pagination component.
 * @param currentPage The current page number
 * @param maxPages The maximum number of pages
 * @param sideBuffer The number of pages to display on either side of the current page
 */
export function generatePageNumbers(
  currentPage: number,
  maxPages: number,
  sideBuffer = 3,
): (number | '...')[] {
  if (maxPages <= 1) {
    return [1];
  }

  const lowerEllipsis = currentPage - (sideBuffer + 1) > 1;
  const upperEllipsis = currentPage + (sideBuffer + 1) < maxPages;

  let startPage;
  let endPage;

  if (maxPages <= 6) {
    return addEllipses(fillRange(1, maxPages), currentPage, maxPages);
  }

  if (lowerEllipsis) {
    startPage = currentPage - sideBuffer;
  } else {
    startPage = 1;
    endPage = Math.ceil(sideBuffer * 2.5);
    return addEllipses(fillRange(startPage, endPage), currentPage, maxPages);
  }

  if (upperEllipsis) {
    endPage = currentPage + sideBuffer;
  } else {
    endPage = maxPages;
  }

  return addEllipses(fillRange(startPage, endPage), currentPage, maxPages);
}
