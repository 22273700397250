import React from 'react';
import PageView from '~/components/legos/PageView';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import usePageView from '~/hooks/usePageView';
import {
  bigCatLockerBottomPageViewId,
  bigCatLockerTopPageViewId,
} from '../../constants/bigCatHardcodedLockerPageViewIds';
import bigCatUserId from '../../constants/bigCatUserId';

interface Props {
  where: 'top' | 'bottom';
}

const LockerPageView: React.FC<Props> = ({ where }) => {
  const { data: user } = useLockerUser();
  const { data: pageView } = usePageView(
    where === 'top' ? bigCatLockerTopPageViewId : bigCatLockerBottomPageViewId,
    {
      enabled: user?.id === bigCatUserId,
    },
  );

  if (user?.id !== bigCatUserId) {
    return null;
  }

  if (pageView) {
    return <PageView pageView={pageView} />;
  }

  return null;
};

export default LockerPageView;
