import React from 'react';
import LockerHeader from '~/components/NewLockers/Header';
import LockerPageView from '~/components/NewLockers/LockerPageView';

interface Props {}

const LockerPageContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  return (
    <>
      <LockerPageView where="top" />
      <LockerHeader />
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">{children}</div>
      <LockerPageView where="bottom" />
    </>
  );
};

export default LockerPageContainer;
