import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import { NextPageContext } from 'next';
import { getQueryClient } from './getQueryClient';

type WithQueryClientReturn<T extends object | void> = T & {
  dehydratedState: DehydratedState;
};

export function withQueryClient<T extends object | void>(
  callback: (queryClient: QueryClient) => (ctx: NextPageContext) => Promise<T>,
) {
  return async (ctx: NextPageContext): Promise<WithQueryClientReturn<T>> => {
    const qc = getQueryClient();
    const props = await callback(qc)(ctx);
    return {
      ...props,
      dehydratedState: dehydrate(qc),
    };
  };
}
