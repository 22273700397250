import { useQuery } from '@tanstack/react-query';
import { fetchEmblemsConfiguration } from '~/api/configuration';
import {
  RailsConfigEmblem,
  RailsConfigEmblemDisplayType,
} from '~/typings/services/rails/configuration';
import { days1inMS } from '../constants/times';

export function useEmblems(
  container: RailsConfigEmblemDisplayType,
  emblems?: string[],
): RailsConfigEmblem[] {
  const { data: emblemsConfig } = useQuery(
    ['emblems'],
    fetchEmblemsConfiguration,
    {
      staleTime: days1inMS,
    },
  );

  if (!emblems || !emblemsConfig) {
    return [];
  }

  return emblemsConfig
    ?.filter(e => e.display.includes(container))
    .filter(e => emblems?.includes(e.slug));
}
