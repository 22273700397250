import Head from 'next/head';
import React from 'react';

interface Props {}

const LockerMeta: React.FC<Props> = () => {
  return (
    <Head>
      <meta name="robots" content="noindex" />
    </Head>
  );
};

export default LockerMeta;
