import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getEnv } from 'common-nextjs';
import { getErrorMessage } from 'fetcher-session';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { Button } from 'ui';
import { putUploadCoverPhoto } from '~/api/user';
import ErrorMessage from '~/components/Form/ErrorMessage';
import LoadingCapableButton from '~/components/Form/LoadingCapableButton';
import Image from '~/components/Image';
import ManagedPaywall from '~/components/SidelinePro/Paywall/ManagedPaywall';
import ProEditableSection from '~/components/SidelinePro/Paywall/ProEditableSection';
import { SidelineProBadge } from '~/components/SidelinePro/ProBadges';
import { AccentS } from '~/components/TradeIn/AccentS';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import { useIsSidelinePro } from '~/hooks/useIsSidelinePro';
import { EditIcon } from '~/public/static/svg/ItemActionIcons';
import getCroppedImg from '~/utils/cropImage';

const PRO_COLOR = '#02c874';
const PRO_PLUS_COLOR = '#FFC700';

export default function LockerCoverPhoto({}: {}) {
  const myLocker = useIsMyLocker();

  if (myLocker) {
    return <EditableCoverPhoto />;
  } else {
    return (
      <Container>
        <DefaultContent />
      </Container>
    );
  }
}

function EditableCoverPhoto() {
  const queryClient = useQueryClient();
  const { data: user } = useLockerUser();
  const cropperRef = useRef<Cropper>(null);

  const [previewImage, setPreviewImage] = useState<null | {
    url: string;
    file: File;
  }>();
  const [error, setError] = useState<string | null>();
  const proEnabled = useIsSidelinePro();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const recentCroppedAreaPixels = useRef<Area & { touched?: boolean }>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    touched: false,
  });

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      recentCroppedAreaPixels.current = croppedAreaPixels;
      recentCroppedAreaPixels.current.touched = true;
    },
    [],
  );

  const { mutate: upload, isLoading: isUploading } = useMutation(
    async () => {
      if (!previewImage) return Promise.resolve();

      const newImage = await getCroppedImg(
        previewImage.url,
        recentCroppedAreaPixels.current,
      );

      return putUploadCoverPhoto(
        new File([newImage], previewImage.file.name, { type: 'image/jpeg' }),
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        setError(null);
        setPreviewImage(null);
      },
      onError: error => {
        setError(getErrorMessage(error));
      },
    },
  );

  useEffect(() => {
    if (previewImage) {
      const url = URL.createObjectURL(previewImage.file);
      setPreviewImage(prev => {
        if (!prev) return prev;

        return {
          ...prev,
          url,
        };
      });

      return () => URL.revokeObjectURL(url);
    }
  }, [previewImage?.file]);

  const content = (
    <div className="absolute left-0 top-0 mx-auto h-full w-full">
      <div className="absolute bottom-4 right-4 z-10">
        {!!previewImage ? (
          <div className="flex gap-2">
            <Button
              variant="outlined"
              className="bg-white"
              onClick={() => {
                setPreviewImage(null);
                setPreviewImage(null);
              }}
            >
              Cancel
            </Button>

            <LoadingCapableButton
              loading={isUploading}
              text="Save"
              loadingText="Saving..."
              onClick={() => upload()}
            />
          </div>
        ) : (
          <div>
            <ErrorMessage errors={error} />

            {proEnabled ? (
              <Dropzone
                accept={['image/*']}
                onDropAccepted={files => {
                  setError(null);
                  setPreviewImage({
                    url: URL.createObjectURL(files[0]),
                    file: files[0],
                  });
                }}
                onDropRejected={() => {
                  setError('Invalid file type');
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <button
                      type="button"
                      className="rounded-full border-2 border-black bg-white p-1.5 shadow-lg"
                      title="Edit cover photo"
                    >
                      <EditIcon className="text-slate-green-500 h-6 w-6" />
                    </button>

                    <input
                      type="file"
                      {...getInputProps()}
                      className="hidden"
                    />
                  </div>
                )}
              </Dropzone>
            ) : (
              <ManagedPaywall source="Cover photo">
                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    className="rounded-full border-2 border-black bg-white p-1.5 shadow-lg"
                    title="Edit cover photo"
                  >
                    <EditIcon className="text-slate-green-500 h-6 w-6" />
                  </button>

                  <SidelineProBadge />
                </div>
              </ManagedPaywall>
            )}
          </div>
        )}
      </div>
    </div>
  );

  if (previewImage) {
    return (
      <div className="relative">
        <Container>
          {content}
          {/*<Image*/}
          {/*  src={previewUrl}*/}
          {/*  className="pointer-events-none absolute left-0 top-0 w-full object-cover object-center "*/}
          {/*/>*/}

          <Cropper
            ref={cropperRef}
            classes={{
              containerClassName: 'absolute left-0 top-0 h-full w-full',
            }}
            rotation={0}
            // aspect={
            //   cropperRef?.current?.containerRef
            //     ? cropperRef.current.containerRef.clientWidth /
            //       cropperRef.current.containerRef.clientHeight
            //     : undefined
            // }
            aspect={94 / 27} // Wacky Rails aspect ratio
            image={previewImage.url}
            crop={crop}
            onCropChange={setCrop}
            zoom={zoom}
            onZoomChange={setZoom}
            zoomWithScroll
            zoomSpeed={0.2}
            minZoom={0.5}
            objectFit="horizontal-cover"
            onCropComplete={onCropComplete}
          />
        </Container>

        {getEnv() === 'development' && (
          <div className="absolute left-4 top-full z-50 mt-4 rounded border bg-white shadow-xl">
            <pre>
              {JSON.stringify(recentCroppedAreaPixels.current, null, 2)}
            </pre>
          </div>
        )}
      </div>
    );
  }

  if (!user?.cover_url) {
    return (
      <ProEditableSection
        centerText="Add a Cover Photo"
        style={{
          paddingTop: '27%',
        }}
      >
        {content}
      </ProEditableSection>
    );
  }

  return (
    <Container>
      {content}
      <DefaultContent />
    </Container>
  );
}

function Container({ children }: { children: React.ReactNode }) {
  const topFeather = (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 16,
        zIndex: 2,
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
      }}
    />
  );
  const bottomFeather = (
    <div
      style={{
        // Bottom feather
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 16,
        zIndex: 2,
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
      }}
    />
  );

  return (
    <div
      className="relative w-full overflow-hidden"
      style={{
        paddingTop: '27%',
      }}
    >
      {topFeather}
      {children}
      {bottomFeather}
    </div>
  );
}

function DefaultContent() {
  const { data: user } = useLockerUser();

  const isPro = user?.emblems?.includes('pro_seller');
  const isProPlus = user?.emblems?.includes('pro_plus_seller');

  if (user?.cover_url) {
    return (
      <Image
        src={user.cover_url}
        className="pointer-events-none absolute left-0 top-0 h-full w-full object-cover object-center"
      />
    );
  } else {
    return (
      <>
        <div
          className="absolute flex h-full w-full items-center justify-center"
          style={{
            background: isPro ? PRO_COLOR : isProPlus ? PRO_PLUS_COLOR : '',
          }}
        />

        <AccentS
          fill="#fff"
          fillOpacity="0.1"
          style={{
            position: 'absolute',
            height: 1100,
            top: -500,
            right: -150,
            // filter: 'blur(2px) drop-shadow(3px 2px 4px rgb(0 0 0 / 0.3))',
            // filter: 'blur(2px)',
          }}
        />

        <AccentS
          fill="#fff"
          fillOpacity="0.1"
          style={{
            position: 'absolute',
            height: 1100,
            top: -20,
            left: -500,
            // filter: 'blur(2px) drop-shadow(3px 2px 4px rgb(0 0 0 / 0.3))',
            // filter: 'blur(2px)',
          }}
        />
      </>
    );
  }
}
