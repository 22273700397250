import React from 'react';
import { Button } from 'ui';
import HelpButton from '~/components/Links/HelpButton';
import { useModals } from '~/contexts/ModalsContext';
import { useSale } from '~/contexts/SaleContext';

const JoinAllSaleRow: React.FC = () => {
  const modals = useModals();
  const { sale } = useSale();

  if (!sale?.id) {
    return null;
  }

  return (
    <div className="mb-4 flex items-center justify-between px-4 md:space-x-4 md:px-0">
      <div className="text-sm">
        Join Sale
        <HelpButton
          className="ml-1"
          onClick={() => modals.openModal('saleGuidance', {})}
        />
      </div>

      <div>
        <Button
          color="darkgrey"
          size="tiny"
          onClick={() => modals.openModal('addAllItemsToSale', {})}
        >
          Add All Items to Sale
        </Button>
      </div>
    </div>
  );
};

export default JoinAllSaleRow;
