import React from 'react';
import { clsx } from 'clsx';

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: any;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, error, disabled, ...props }, ref) => (
    <textarea
      {...props}
      ref={ref}
      disabled={disabled}
      className={clsx(
        'w-full rounded border px-4 py-2 transition duration-200 focus:outline-none focus:ring',
        {
          'border-red-500 ring-red-500/30': error,
          'rounded bg-white focus:border-green-500': !error,
          'bg-off-white-500 text-slate-green-500 cursor-not-allowed': disabled,
        },
        className,
      )}
    />
  ),
);
