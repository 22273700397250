import { clsx } from 'clsx';
import React, { CSSProperties } from 'react';

export default function ProEditableSection({
  centerText,
  children,
  className,
  style,
}: {
  centerText?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <div
      className={clsx(
        'bg-grey-100 relative rounded-sm border-dashed border-green-500',
        className,
      )}
      style={{
        ...style,
        borderWidth: 3,
      }}
    >
      {centerText && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center text-lg font-semibold">
          {centerText}
        </div>
      )}

      {children}
    </div>
  );
}
