import React, { useState } from 'react';
import SidelineProPaywallModal from '~/components/SidelinePro/Paywall/PaywallModal';
import {
  useIsSidelinePro,
  useIsSidelineProAvailable,
} from '~/hooks/useIsSidelinePro';
import { PaywallSource } from '~/services/analytics/events/sidelinePro';

export default function ManagedPaywall({
  children,
  source,
}: {
  children: React.ReactNode;
  source: PaywallSource;
}) {
  const proAvailable = useIsSidelineProAvailable();
  const proEnabled = useIsSidelinePro();
  const [open, setOpen] = useState(false);

  if (!proAvailable) {
    return null;
  }

  if (proEnabled) {
    return children;
  }

  return (
    <>
      {React.Children.map(children, child =>
        React.cloneElement(child as React.ReactElement, {
          onClick: () => setOpen(true),
        }),
      )}

      <SidelineProPaywallModal
        open={open}
        onClose={() => setOpen(false)}
        source={source}
      />
    </>
  );
}
