import { clsx } from 'clsx';
import React from 'react';
import { Button, ButtonProps } from 'ui';
import SimpleFade from '~/components/Transitions/SimpleFade';
import SlideInFromRight from '~/components/Transitions/SlideInFromRight';

const Spinner: React.FC = props => (
  <svg
    className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

interface Props extends ButtonProps {
  buttonClassName?: string;
  className?: string;
  children?: never;
  disableWhileLoading?: false;
  loading: boolean;
  text: string;
  // If you pass "null", there will be no text, just a spinner
  loadingText?: string | null;
}

const LoadingCapableButton: React.FC<Props> = ({
  buttonClassName,
  className,
  disabled,
  disableWhileLoading = true,
  fluid,
  loading,
  loadingText,
  text,
  ...buttonProps
}) => {
  let content: React.ReactNode;

  if (loading) {
    if (loadingText === null) {
      content = null;
    } else if (loadingText) {
      content = loadingText;
    } else if (!loadingText) {
      content = text;
    }
  } else {
    content = text;
  }

  const shouldBeDisabled = disabled || (disableWhileLoading ? loading : false);

  return (
    <div className={clsx('relative', { 'w-full flex-1': fluid }, className)}>
      <Button
        className={clsx('flex items-center justify-center', buttonClassName)}
        disabled={shouldBeDisabled}
        {...buttonProps}
        fluid={fluid}
      >
        <SlideInFromRight show={loading}>
          <Spinner />
        </SlideInFromRight>

        <span>{content}</span>
      </Button>

      <SimpleFade
        show={shouldBeDisabled}
        className="absolute inset-0 z-10 cursor-not-allowed bg-white/30"
      />
    </div>
  );
};

export default LoadingCapableButton;
