import React from 'react';
import { useModals } from '~/contexts/ModalsContext';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import { RailsUser } from '~/typings/services/rails/user';

interface Props {
  user?: RailsUser;
}

const LockerAvatarUpload: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const { data: user } = useLockerUser();
  const myLocker = useIsMyLocker();
  const modals = useModals();

  if (myLocker && user) {
    return (
      <button
        className="relative self-start"
        onClick={() =>
          modals.openModal('avatarUpload', {
            initialAvatarUrl: user.avatar_url,
          })
        }
      >
        {children}
      </button>
    );
  } else {
    return <>{children}</>;
  }
};

export default LockerAvatarUpload;
