import { useRouter } from 'next/router';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { fetchLockerUserData } from '~/api/locker';
import { minutes30inMS } from '../../constants/times';
import { NextPageContext } from 'next';
import { UseQueryResult } from '@tanstack/react-query';
import { RailsUser } from '~/typings/services/rails/user';

export async function prefetchLockerUser(
  username: string,
  queryClient: QueryClient,
  ctx: NextPageContext,
) {
  return await queryClient.fetchQuery(['lockers', username], () =>
    fetchLockerUserData.call(ctx, username),
  );
}

export default function useLockerUser(): UseQueryResult<RailsUser> {
  const router = useRouter();

  return useQuery(
    ['lockers', router.query.username],
    () => fetchLockerUserData(router.query.username),
    {
      staleTime: minutes30inMS,
    },
  );
}
