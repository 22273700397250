import {
  QueryClient,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { fetchPublicProfile } from '~/api/locker';
import { RailsProfile } from '~/typings/services/rails/profiles';

export async function prefetchPublicProfile(
  usernameOrUuid: string,
  queryClient: QueryClient,
) {
  try {
    return await queryClient.prefetchQuery(
      ['public-profiles', usernameOrUuid],
      () => fetchPublicProfile(usernameOrUuid),
    );
  } catch (e) {}
}

export default function usePublicProfile(
  usernameOrUuid?: string,
  options?: UseQueryOptions<RailsProfile>,
): UseQueryResult<RailsProfile> {
  return useQuery<RailsProfile>(
    ['public-profiles', usernameOrUuid],
    () => fetchPublicProfile(usernameOrUuid!),
    {
      enabled: !!usernameOrUuid,
      retry: false,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}
