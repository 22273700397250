import React from 'react';
import { pluralize, thousandsSeparator } from 'utils';
import LockerLink from '~/components/Links/LockerLink';
import FollowButton from '~/components/NewLockers/FollowButton';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import { useIsSidelineProAvailable } from '~/hooks/useIsSidelinePro';

interface CountProps {
  count: number;
  plural: string;
  singular: string;
}

const Count: React.FC<CountProps> = ({ count, plural, singular }) => (
  <div className="text-center">
    <div className="text-base font-semibold">{thousandsSeparator(count)}</div>
    <div className="text-slate-green-500 text-xs">
      {pluralize(count, singular, plural)}
    </div>
  </div>
);

interface Props {}

const FollowCounts: React.FC<Props> = () => {
  const { data: user } = useLockerUser();
  const myLocker = useIsMyLocker();
  const proAvailable = useIsSidelineProAvailable();

  if (!user) {
    return null;
  }

  return (
    <>
      <LockerLink username={user.username} page="followers">
        <Count
          count={user.follower_count}
          plural="Followers"
          singular="Follower"
        />
      </LockerLink>

      <LockerLink username={user.username} page="following">
        <Count
          count={user.following_count}
          plural="Following"
          singular="Following"
        />
      </LockerLink>

      {myLocker && !proAvailable && (
        <Count
          count={user.available_bumps ?? 0}
          plural="Bumps"
          singular="Bump"
        />
      )}

      {!myLocker && (
        <div>
          <FollowButton following={user.following} userId={user.id} />
        </div>
      )}
    </>
  );
};

export default FollowCounts;
