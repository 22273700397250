import { clsx } from 'clsx';
import React from 'react';

interface Props {
  className?: string;
}

export const PaginationContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  ...props
}) => (
  <div
    className={clsx(
      'mt-8 flex items-center justify-center space-x-2 text-xs',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);
