import { useMobile } from 'common-nextjs';
import React, { useState } from 'react';
import { Toggle } from 'ui';
import DesktopLockerHeader from '~/components/NewLockers/Header/DesktopLockerHeader';
import MobileLockerHeader from '~/components/NewLockers/Header/MobileLockerHeader';
import DesktopLockerProHeader from '~/components/NewLockers/Header/ProHeader/DesktopLockerProHeader';
import MobileLockerProHeader from '~/components/NewLockers/Header/ProHeader/MobileLockerProHeader';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import {
  useIsSidelinePro,
  useIsSidelineProAvailable,
} from '~/hooks/useIsSidelinePro';

const containerCx = 'mx-auto max-w-screen-xl px-4';

export default function LockerHeader() {
  const mobile = useMobile();
  const { data: user } = useLockerUser();
  const myLocker = useIsMyLocker();

  const proAvailable = useIsSidelineProAvailable();
  if (!proAvailable) {
    return (
      <div className={containerCx}>
        {mobile ? <MobileLockerHeader /> : <DesktopLockerHeader />}
      </div>
    );
  }

  if (myLocker) {
    return <MyLockerHeader />;
  }

  if (user?.emblems?.find(e => e === 'pro_plus_seller' || e === 'pro_seller')) {
    return mobile ? <MobileLockerProHeader /> : <DesktopLockerProHeader />;
  }

  return (
    <div className={containerCx}>
      {mobile ? <MobileLockerHeader /> : <DesktopLockerHeader />}
    </div>
  );
}

function MyLockerHeader() {
  const mobile = useMobile();
  const proEnabled = useIsSidelinePro();
  const [proTesting, setProTesting] = useState(true);

  let content: React.ReactNode;

  if (proEnabled || proTesting) {
    content = mobile ? <MobileLockerProHeader /> : <DesktopLockerProHeader />;
  } else {
    content = (
      <div className={containerCx}>
        {mobile ? <MobileLockerHeader /> : <DesktopLockerHeader />}
      </div>
    );
  }

  return (
    <>
      {!proEnabled && (
        <div className="border-b py-4">
          <div className={containerCx}>
            <div className="flex flex-col items-center gap-4 md:flex-row">
              <div className="text-balance flex-1 text-center font-semibold md:text-left">
                Curious what your locker would look like with Sideline Pro?
              </div>

              <label className="flex items-center gap-2">
                <span>Current View</span>
                <Toggle value={proTesting} onChange={setProTesting} />
                <span>Sideline Pro</span>
              </label>
            </div>
          </div>
        </div>
      )}

      {content}
    </>
  );
}
