import React from 'react';
import { Avatar } from '~/components/Avatar';
import LockerBumpsRow from '~/components/Bumps/LockerBumpsRow';
import UserProfileBullets from '~/components/ItemDetails/UserProfileBullets';
import LockerAvatarUpload from '~/components/NewLockers/AvatarUpload/LockerAvatarUpload';
import FollowCounts from '~/components/NewLockers/Header/Elements/FollowCounts';
import InfoAndEmblems from '~/components/NewLockers/Header/Elements/InfoAndEmblems';
import JoinAllSaleRow from '~/components/NewLockers/JoinAllSaleRow';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import usePublicProfile from '~/hooks/lockers/usePublicProfile';

interface Props {}

const DesktopLockerHeader: React.FC<Props> = () => {
  const { data: user } = useLockerUser();
  const { data: profile } = usePublicProfile(user?.username);
  const myLocker = useIsMyLocker();

  return (
    <section className="mb-4 mt-4 border-b pb-4">
      <div className="flex space-x-4">
        <div className="flex-0">
          <LockerAvatarUpload>
            <Avatar avatarUrl={user?.avatar_url} size="xl" />
          </LockerAvatarUpload>
        </div>

        {/* Right Column */}
        <div className="flex-1">
          {/* Info on the left, followers on the right */}
          <div className="flex w-full justify-between gap-4">
            <div className="flex-1">
              <InfoAndEmblems />
            </div>
            <FollowCounts />
          </div>

          {profile && (
            <div className="mt-4 grid w-full grid-cols-2 gap-6 text-sm md:grid-cols-4 xl:grid-cols-6">
              <UserProfileBullets profile={profile} />
            </div>
          )}
        </div>
      </div>

      {myLocker && (
        <div className="mt-4 flex space-x-8">
          <LockerBumpsRow />
          <JoinAllSaleRow />
        </div>
      )}
    </section>
  );
};

export default DesktopLockerHeader;
