import React from 'react';
import { Button } from 'ui';
import HelpButton from '~/components/Links/HelpButton';
import { useModals } from '~/contexts/ModalsContext';
import useBumpInfo from '~/hooks/useBumpInfo';
import { useIsSidelineProAvailable } from '~/hooks/useIsSidelinePro';

const LockerBumpsRow: React.FC = () => {
  const { data: bumpsInfo, refetch } = useBumpInfo();
  const modals = useModals();

  const proAvailable = useIsSidelineProAvailable();
  if (proAvailable) {
    return null;
  }

  return (
    <div className="mb-4 flex items-center justify-between px-4 md:space-x-4 md:px-0">
      <div className="text-sm">
        Available Bumps: {bumpsInfo?.available_bumps}
        <HelpButton
          className="ml-1"
          onClick={() => modals.openModal('bumpsGuidance', {})}
        />
      </div>

      <div>
        <Button
          size="tiny"
          onClick={() =>
            modals.openModal('bumpsPurchase', {
              onSuccessfulPurchase: () => refetch(),
            })
          }
        >
          Get More Bumps
        </Button>
      </div>
    </div>
  );
};

export default LockerBumpsRow;
