import { useRouter } from 'next/router';
import React from 'react';
import Image from '~/components/Image';
import NewTabLink from '~/components/Links/NewTabLink';
import SE from '~/public/static/sportsengine.png';

interface Props {}

const SportsEngineSoftLanding: React.FC<Props> = () => {
  const router = useRouter();

  if (router.query?.src !== 'sportsengine') {
    return null;
  }

  return (
    <div className="container px-4">
      <div className="mb-8 mt-2 flex flex-col justify-center gap-2 rounded border px-2 py-3 text-lg shadow-sm md:mt-4 md:flex-row">
        <div className="mb-2 text-center md:mb-0">
          <span className="font-semibold">Welcome to SidelineSwap</span> -
          Official Marketplace Partner of
        </div>

        <NewTabLink href="https://sportsengine.com">
          <Image className="mx-auto" src={SE} />
        </NewTabLink>
      </div>
    </div>
  );
};

export default SportsEngineSoftLanding;
