import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useState } from 'react';
import { Button, Label, TextArea } from 'ui';
import { putUpdateBio } from '~/api/user';
import ErrorMessage from '~/components/Form/ErrorMessage';
import LoadingCapableButton from '~/components/Form/LoadingCapableButton';
import ManagedPaywall from '~/components/SidelinePro/Paywall/ManagedPaywall';
import ProEditableSection from '~/components/SidelinePro/Paywall/ProEditableSection';
import { SidelineProBadge } from '~/components/SidelinePro/ProBadges';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import usePublicProfile from '~/hooks/lockers/usePublicProfile';
import { useIsSidelinePro } from '~/hooks/useIsSidelinePro';
import { EditIcon } from '~/public/static/svg/ItemActionIcons';

const MAX_BIO = 300;

export default function ProLockerBio({}: {}) {
  const { data: user } = useLockerUser();
  const { data: profile } = usePublicProfile(user?.username);
  const myLocker = useIsMyLocker();

  if (myLocker) {
    return <EditableBio />;
  }

  if (!profile?.bio) {
    return null;
  }

  return (
    <div>
      <div>
        <Label>About Me</Label>
      </div>
      <div className="prose prose-sm">{profile.bio}</div>
    </div>
  );
}

function EditableBio() {
  const queryClient = useQueryClient();
  const { data: user } = useLockerUser();
  const { data: profile } = usePublicProfile(user?.username);
  const proEnabled = useIsSidelinePro();

  const [editing, setEditing] = useState(false);
  const [editingText, setEditingText] = useState(profile?.bio || '');

  const {
    error,
    mutate: updateBio,
    isLoading: isUpdating,
  } = useMutation(() => putUpdateBio(editingText), {
    onSuccess: () => {
      queryClient.invalidateQueries();
      setEditing(false);
    },
  });

  if (editing) {
    return (
      <div className="mb-4">
        <TextArea
          className="mb-2 h-40 w-full"
          value={editingText}
          onChange={e => setEditingText(e.target.value)}
          autoFocus
        />
        <ErrorMessage errors={error} />

        <div className="flex gap-2">
          <div
            className={clsx(
              'flex-1 text-sm font-semibold',
              editingText.length > MAX_BIO ? 'text-red-500' : '',
            )}
          >
            {editingText.length} / {MAX_BIO}
          </div>

          <Button variant="outlined" onClick={() => setEditing(false)}>
            Cancel
          </Button>

          <LoadingCapableButton
            loading={isUpdating}
            text="Save"
            loadingText="Saving..."
            disabled={editingText.length > MAX_BIO}
            onClick={() => updateBio()}
          />
        </div>
      </div>
    );
  }

  if (!profile?.bio) {
    return (
      <ProEditableSection centerText="Add a Bio" className="h-32">
        <div className="absolute right-4 top-4 flex items-center gap-2">
          <ManagedPaywall source="Bio">
            <button
              type="button"
              className="rounded-full border-2 border-black bg-white p-1.5"
              onClick={() => setEditing(true)}
              title="Edit bio"
            >
              <EditIcon className="text-slate-green-500 h-4 w-4" />
            </button>
          </ManagedPaywall>

          {!proEnabled && <SidelineProBadge />}
        </div>
      </ProEditableSection>
    );
  }

  return (
    <div className="relative">
      <div>
        <Label>About Me</Label>
      </div>
      <div className="prose prose-sm">{profile.bio}</div>

      {!editing && (
        <div className="absolute -top-8 right-0">
          <ManagedPaywall source="Bio">
            <button
              type="button"
              className="rounded-full border-2 border-black bg-white p-1.5"
              onClick={() => setEditing(true)}
              title="Edit bio"
            >
              <EditIcon className="text-slate-green-500 h-4 w-4" />
            </button>
          </ManagedPaywall>
        </div>
      )}
    </div>
  );
}
