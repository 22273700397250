import { useMobile } from 'common-nextjs';
import { pluralize, thousandsSeparator } from 'utils';
import Tooltip from '~/components/Form/Tooltip';
import HelpButton from '~/components/Links/HelpButton';
import LockerLink from '~/components/Links/LockerLink';
import { RailsUser } from '~/typings/services/rails/user';

function LockerFeedbackHeader({
  user,
}: {
  user: Pick<RailsUser, 'feedback' | 'username'>;
}) {
  const mobile = useMobile();
  if (!user) {
    return null;
  }

  return (
    <>
      {user.feedback.count > 0 ? (
        <>
          <LockerLink
            username={user.username}
            page="feedback"
            className="underline"
          >
            {user.feedback.score}% Positive Feedback
          </LockerLink>{' '}
          <LockerLink
            username={user.username}
            className="underline"
            itemState="sold"
          >
            ({thousandsSeparator(user.feedback.count)}{' '}
            {/* Not an error, this is intentionally feedback_count and sale(s) */}
            {pluralize(user.feedback.count, 'sale', 'sales')})
          </LockerLink>
        </>
      ) : (
        'No Feedback'
      )}

      <Tooltip
        direction={mobile ? 'top' : 'right'}
        tooltip={
          <p>
            Feedback score is calculated based on
            <br /> the last 24 months of sales.
          </p>
        }
      >
        <HelpButton className="!mt-0 ml-0 mr-6" />
      </Tooltip>
    </>
  );
}

export default LockerFeedbackHeader;
