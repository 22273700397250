import { clsx } from 'clsx';
import Image from '~/components/Image';
import UserProfileBullets from '~/components/ItemDetails/UserProfileBullets';
import NewTabLink from '~/components/Links/NewTabLink';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import usePublicProfile from '~/hooks/lockers/usePublicProfile';
import { useEmblems } from '~/hooks/useEmblems';

export default function ProLockerEmblemsAndInfo({}: {}) {
  const { data: user } = useLockerUser();
  const { data: profile } = usePublicProfile(user?.username);
  const emblems = useEmblems('locker', user?.emblems);

  if (!profile) {
    return null;
  }

  return (
    <div
      // TODO: Maybe easier to just use flex here?
      className={clsx(
        'text-slate-green-500 bg-grey-50 w-full grid-cols-1 gap-4 rounded p-4',
        emblems.length > 0 ? 'grid md:grid-cols-2' : 'inline-grid md:w-auto',
      )}
    >
      <div className="space-y-2 text-sm">
        {profile && <UserProfileBullets profile={profile} />}
      </div>

      <div className="space-y-2">
        {emblems?.map(emblem => (
          <div key={emblem.slug}>
            <NewTabLink
              href={emblem.help_url}
              className="inline-flex items-center gap-2"
            >
              <Image src={emblem.small_url} className="h-5 max-w-none" />
              <span>{emblem.name}</span>
            </NewTabLink>
          </div>
        ))}
      </div>
    </div>
  );
}
