import { generatePageNumbers } from 'common-nextjs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { Button } from 'ui';
import { qsUpdateString } from 'utils';
import { PaginationContainer } from '~/components/Pagination/elements';
import EllipsisIcon from '~/public/static/svg/EllipsisIcon';
import { RailsPaging } from '~/typings/services/rails/paging';

interface Props {
  paging: RailsPaging;
}

const NumberedPaginator: React.FC<Props> = ({ paging }) => {
  const router = useRouter();
  const [path, query] = router.asPath.split('?');

  const pageNumbers = generatePageNumbers(paging.page, paging.total_pages, 2);

  function getQuery(page: number) {
    return qsUpdateString([query, { page: page === 1 ? undefined : page }]);
  }

  const prev =
    paging.page == 1
      ? null
      : qsUpdateString([
          query,
          {
            page: paging.page == 2 ? null : paging.page - 1,
          },
        ]);

  const next =
    (paging.total_pages != null && paging.page < paging.total_pages) ||
    paging.has_next_page
      ? qsUpdateString([
          query,
          {
            page: paging.page + 1,
          },
        ])
      : null;

  const prevButton = (
    <Button variant="outlined" size="tiny" disabled={prev == null}>
      Prev
    </Button>
  );

  const nextButton = (
    <Button variant="outlined" size="tiny" disabled={next == null}>
      Next
    </Button>
  );

  return (
    <PaginationContainer>
      {typeof prev === 'string' ? (
        <Link href={router.route + prev} as={path + prev} rel="prev">
          {prevButton}
        </Link>
      ) : (
        prevButton
      )}

      {!pageNumbers.includes(1) && (
        <Link
          href={router.route + getQuery(1)}
          as={path + getQuery(1)}
          passHref
        >
          <Button variant="outlined" size="tiny">
            1
          </Button>
        </Link>
      )}

      {pageNumbers.length > 1 ? (
        pageNumbers.map((page, i) => {
          if (page === '...') {
            return (
              <EllipsisIcon
                key={`ellipsis-${i}`}
                className="text-slate-green-500"
              />
            );
          }

          const queryString = getQuery(+page);

          return (
            <Link
              key={page}
              href={router.route + queryString}
              as={path + queryString}
            >
              <Button
                variant={page === paging.page ? 'contained' : 'outlined'}
                size="tiny"
              >
                {page}
              </Button>
            </Link>
          );
        })
      ) : (
        <Button size="tiny">{paging.page}</Button>
      )}

      {paging.total_pages > 1 &&
        !paging.total_count_truncated &&
        !pageNumbers.includes(paging.total_pages) && (
          <Link
            href={router.route + getQuery(paging.total_pages)}
            as={path + getQuery(paging.total_pages)}
          >
            <Button variant="outlined" size="tiny">
              {paging.total_pages}
            </Button>
          </Link>
        )}

      {typeof next === 'string' ? (
        <Link href={router.route + next} as={path + next} rel="next">
          {nextButton}
        </Link>
      ) : (
        nextButton
      )}
    </PaginationContainer>
  );
};

export default NumberedPaginator;
