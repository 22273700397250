import { Avatar } from '~/components/Avatar';
import LockerAvatarUpload from '~/components/NewLockers/AvatarUpload/LockerAvatarUpload';
import FollowCounts from '~/components/NewLockers/Header/Elements/FollowCounts';
import LockerFeedbackHeader from '~/components/NewLockers/Header/Elements/LockerFeedbackHeader';
import LockerCoverPhoto from '~/components/NewLockers/Header/ProHeader/LockerCoverPhoto';
import ProLockerBio from '~/components/NewLockers/Header/ProHeader/ProLockerBio';
import ProLockerEmblemsAndInfo from '~/components/NewLockers/Header/ProHeader/ProLockerEmblemsAndInfo';
import JoinAllSaleRow from '~/components/NewLockers/JoinAllSaleRow';
import SidelineProBadgesEmblems from '~/components/SidelinePro/SidelineProBadgesEmblems';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import { EditIcon } from '~/public/static/svg/ItemActionIcons';

export default function MobileLockerProHeader({}: {}) {
  const { data: user } = useLockerUser();
  const myLocker = useIsMyLocker();

  if (!user) {
    return null;
  }

  return (
    <div className="mb-4">
      <LockerCoverPhoto />

      <div className="relative mx-auto -mt-8 w-full max-w-screen-xl px-4">
        <div className="flex flex-wrap items-center justify-between gap-x-4">
          <div
            style={{
              zIndex: 4,
            }}
          >
            <LockerAvatarUpload>
              <Avatar
                size="2xl"
                avatarUrl={user.avatar_url}
                className="relative z-10 mb-4"
              />

              {myLocker && (
                <div className="absolute bottom-4 right-0 z-10 rounded-full border-2 border-black bg-white p-1.5">
                  <EditIcon className="text-slate-green-500 h-4 w-4" />
                </div>
              )}
            </LockerAvatarUpload>
          </div>

          <FollowCounts />
        </div>

        <div className="mb-4 flex items-center gap-4">
          <div>
            <div className="flex gap-4 text-xl font-semibold">
              {user.username}{' '}
              <SidelineProBadgesEmblems emblems={user.emblems} />
            </div>

            <div className="text-sm">
              <LockerFeedbackHeader user={user} />
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <ProLockerBio />
          <div>
            <ProLockerEmblemsAndInfo />
          </div>
        </div>

        {myLocker && <JoinAllSaleRow />}
      </div>
    </div>
  );
}
