import React from 'react';
import Image from '~/components/Image';
import NewTabLink from '~/components/Links/NewTabLink';
import LockerFeedbackHeader from '~/components/NewLockers/Header/Elements/LockerFeedbackHeader';
import SidelineProBadgesEmblems from '~/components/SidelinePro/SidelineProBadgesEmblems';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import { useEmblems } from '~/hooks/useEmblems';

interface Props {}

const InfoAndEmblems: React.FC<Props> = () => {
  const { data: user } = useLockerUser();
  const emblems = useEmblems('locker', user?.emblems);

  if (!user) {
    return null;
  }

  return (
    <div className="space-y-2 text-sm">
      <h1 className="flex gap-2 text-sm font-semibold">
        {user.username} <SidelineProBadgesEmblems emblems={user.emblems} />
      </h1>
      <LockerFeedbackHeader user={user} />

      <div className="grid grid-cols-2 gap-4 md:grid-cols-4 xl:grid-cols-6">
        {emblems?.map(emblem => (
          <NewTabLink
            key={emblem.slug}
            href={emblem.help_url}
            className="text-slate-green-500 flex items-center"
          >
            <span className="mr-2 w-4">
              <Image src={emblem.small_url} className="h-4 max-w-none" />
            </span>
            <span>{emblem.name}</span>
          </NewTabLink>
        ))}
      </div>
    </div>
  );
};

export default InfoAndEmblems;
