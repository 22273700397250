'use client';

import { clsx } from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';

export type ToggleProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'onChange' | 'value'
> & {
  className?: string;
  disabled?: boolean;
  onChange?: (enabled: boolean) => void;
  size?: 'medium' | 'large';
  value?: boolean;
};

export const Toggle: React.FC<ToggleProps> = ({
  className,
  onChange,
  value: active,
  size = 'medium',
  title,
  disabled,
  ...props
}) => {
  const translate =
    size === 'medium'
      ? 'translate-x-4'
      : size === 'large'
        ? 'translate-x-6'
        : '';

  return (
    <button
      type="button"
      {...props}
      className={clsx(
        'disabled:bg-grey-200 flex-none cursor-pointer whitespace-nowrap rounded-full p-0.5 text-left transition-colors duration-300 ease-in-out focus-within:ring disabled:cursor-not-allowed',
        active ? 'bg-green-500' : 'bg-pastel-green-500',
        className,
        {
          'h-4 w-8': size === 'medium',
          'h-6 w-12': size === 'large',
        },
      )}
      disabled={disabled}
      aria-disabled={disabled}
      aria-checked={active ? true : undefined}
      role="switch"
      onClick={() => onChange?.(!active)}
    >
      <span
        aria-hidden="true"
        className={clsx(
          'inline-block transform rounded-full align-top transition duration-300 ease-in-out',
          active ? `${translate} bg-white` : 'bg-slate-green-500 translate-x-0',
          {
            'h-3 w-3': size === 'medium',
            'h-5 w-5': size === 'large',
          },
        )}
      />
    </button>
  );
};
